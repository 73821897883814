button {
  margin: 1rem;
}

.customForm {
  background-color: white;
  padding: 1rem;
  border-radius: 10px;
  color: black;
}

